import {
	Types,
	formatters,
	DateRangeFormat,
	ContentTransformers,
	generateContentURL,
	buildURLWithParams,
	CATALOG_URLS,
	generateOnDemandContentURL,
	booleanFilter,
	BOOKING_URLS
} from 'common';
import { IntlShape, useIntl } from 'react-intl';
import {
	TopicSortOrderQuery,
	OnDemandCoursesQuery,
	MediathekCoursesQuery
} from '../../../graphql/catalog/queries';
import { COURSES_PER_PAGE } from '../MediathekHome.utils';
import { DateFormat, formatDateForDB } from 'components';
import Dayjs from 'dayjs';
import { contentUtils } from '../../../utils';
import { CoursesTableProps, CourseTableItem } from './components/CoursesTable';
import { groupBy, sortBy } from 'lodash';

export function getFetchCoursesVariables(country: CountryCode) {
	return {
		searchQuery: {
			filters: [
				{
					field: 'contentType',
					value: [Types.ContentType.Course]
				},
				{
					field: 'publishedDomains',
					value: [country]
				},
				{
					field: 'endDateTime',
					operation: Types.SearchFilterOperation.GreaterThan,
					value: [formatDateForDB(Dayjs.utc().endOf('day'))]
				},
				{
					field: 'category',
					operation: Types.SearchFilterOperation.NotEqual,
					value: [Types.Category.FomfDigital]
				}
			],
			aggregations: [
				{
					field: 'productCode',
					name: 'productEvents',
					type: 'TERMS' as const
				}
			],
			orders: ['startDateTime']
		}
	};
}

export const getCustomTopicSortOrder = (selectedTopicCode: string | undefined) => {
	return selectedTopicCode
		? {
				orderBy: `priorities.${selectedTopicCode}`
		  }
		: {};
};

type GetMostRelevantTopicFromProductTopics = {
	productTopics: Array<{ id: Types.UUID; code: string }>;
	topicSortOrder: PrepareCoursesDataProps['topicData'];
};

/**
 * Sometimes a Course (a Product) can have multiple topics, e.g. RHEUM and NEPHRO topics for a RN product; in order to correctly sort the table we need to find the most relevant topic for the product based on the TopicSortOrder (this SortOrder depends on user's selected speciality).
 */
const getMostRelevantTopicFromProductTopics = ({
	productTopics,
	topicSortOrder = []
}: GetMostRelevantTopicFromProductTopics): string | null => {
	if (productTopics.length === 1 || topicSortOrder.length === 0) {
		return productTopics[0]?.id || null;
	}

	const mostRelevantTopicId =
		topicSortOrder.find((topic) =>
			productTopics.some((productTopic) => productTopic.id === topic.id)
		)?.id || null;

	return mostRelevantTopicId;
};

export const calculateModuleDuration = (
	content: OnDemandCoursesQuery['courses']['data'][number],
	formatMessage: IntlShape['formatMessage']
) => {
	const durations = content.videos.map((v) => v.source?.duration).filter(booleanFilter);
	const totalSeconds = durations.reduce((acc, cv) => acc + cv, 0);
	const avgMins = totalSeconds / durations.length / 60;
	const nearest45 = Math.round(avgMins / 45) * 45;
	return `${content.modules.length} x ${nearest45} ${formatMessage({
		id: 'catalog.min.abbreviation'
	})}`;
};

export const calculateModuleVideosDuration = (
	videos: OnDemandCoursesQuery['courses']['data'][number]['videos']
) => {
	const durations = videos.map((v) => v.source?.duration).filter(booleanFilter);
	const totalSeconds = durations.reduce((acc, cv) => acc + cv, 0);
	const mins = totalSeconds / durations.length / 60;
	const nearest45 = Math.round(mins / 45) * 45;
	return Math.max(nearest45, 45);
};

const generateBookingUrl = ({
	id,
	isMember,
	contentType,
	brand,
	externalId
}: {
	id: string;
	isMember: boolean;
	contentType: Types.ContentType;
	brand: Types.ProductBrand | null | undefined;
	externalId: string;
}): string => {
	if (
		contentType !== Types.ContentType.OnDemandAccredited &&
		brand !== Types.ProductBrand.Syfat
	) {
		return `/booking/events/${externalId}`;
	}
	if (isMember) {
		return buildURLWithParams(CATALOG_URLS.onDemandAccreditationCourse, [id]);
	}
	return BOOKING_URLS.memberships;
};

export const transformContentToCourseTableItem = ({
	content,
	topicData,
	courseTitle,
	totalCoursesForProduct = '',
	locale,
	messages,
	isOnDemandTable,
	isMember,
	formatMessage
}: {
	content: (
		| MediathekCoursesQuery['contentDocuments']['data'][number]
		| OnDemandCoursesQuery['courses']['data'][number]
	) & {
		validDate?: string | null;
	};
	topicData?: PrepareCoursesDataProps['topicData'];
	courseTitle: CourseTableItem['courseTitle'];
	locale: Locale;
	messages: Types.Messages;
	formatMessage: IntlShape['formatMessage'];
	totalCoursesForProduct?: string;
	isMember: boolean;
	isOnDemandTable?: boolean;
}): CourseTableItem => {
	let participationCity: string | null = null;
	if (
		content.participationOption == Types.ContentParticipationOption.Hybrid ||
		content.participationOption == Types.ContentParticipationOption.OnSite
	) {
		participationCity = formatters.formatTranslation(content.city, { locale }) ?? null;
	}
	const { brand, city, contentType, specialities, product, title } = content;

	return {
		id: content.id,
		courseIconUrl: content.product?.specialityIconUrl ?? '',
		courseIconColor: content.product?.iconPrimaryColor ?? null,
		courseTitle,
		subtitle: content.subtitle,
		courseDates: isOnDemandTable
			? content.validDate
				? formatters.formatDate(Dayjs(content.validDate), DateFormat.date)
				: ''
			: formatters.formatDateRange(
					Dayjs(content.startDateTime),
					Dayjs(content.endDateTime),
					DateRangeFormat.constantDate
			  ),
		topicId: getMostRelevantTopicFromProductTopics({
			productTopics: content.product?.topics || [],
			topicSortOrder: topicData
		}),
		productURL: buildURLWithParams(CATALOG_URLS.product, [content.product?.code || '']),
		totalCoursesForProduct,
		participationType: formatters.formatEnum(content.participationOption, {
			messages,
			options: ContentTransformers.participantOption
		}),
		participationCity,
		courseCredits: contentUtils.generateCreditsString(content.credits, locale),
		participationOptionIconURL: contentUtils.getParticipationOptionIconURL(content),
		capacityStatus: content.capacityStatus,
		courseURL: isOnDemandTable
			? generateOnDemandContentURL(content)
			: generateContentURL(content, false),
		contentType: content.contentType,
		bookingURL: generateBookingUrl({
			id: content.id,
			isMember,
			contentType: content.contentType,
			brand: content.brand,
			externalId: content.externalId || ''
		}),
		contentLocale: content.locale as Locale | undefined,
		segmentFields: {
			brand,
			city,
			contentType,
			specialities,
			product,
			title,
			contentId: content.contentId
		},
		brand: content.brand,
		modulesDuration:
			'modules' in content ? calculateModuleDuration(content, formatMessage) : undefined,
		numberOfVideos: 'videos' in content ? content.videos.length : undefined
	};
};

interface SortCoursesByTopicProps {
	courses: CoursesTableProps['courses'];
	topicData: TopicSortOrderQuery['topicDocuments']['data'];
}

const sortCoursesByTopic = ({ courses, topicData }: SortCoursesByTopicProps) => {
	let output = [];

	for (const topic of topicData) {
		const relevantProducts = courses.filter((course) => course.topicId === topic?.id);
		output.push(...relevantProducts);

		if (output.length > COURSES_PER_PAGE) {
			output = output.slice(0, COURSES_PER_PAGE);
			break;
		}
	}

	return output;
};

interface PrepareTopicCoursesDataProps {
	topicData: TopicSortOrderQuery['topicDocuments']['data'] | undefined;
	coursesData: MediathekCoursesQuery['contentDocuments'] | undefined;
	intl: ReturnType<typeof useIntl>;
	isOnDemandTable?: boolean;
	isMember: boolean;
}

interface PrepareCoursesDataProps {
	topicData: TopicSortOrderQuery['topicDocuments']['data'] | undefined;
	coursesData:
		| OnDemandCoursesQuery['courses']
		| MediathekCoursesQuery['contentDocuments']
		| undefined;
	intl: ReturnType<typeof useIntl>;
	isOnDemandTable?: boolean;
	toFilterTopicCode?: string;
	isMember: boolean;
}

// This function will use all courses passed to it, instead of only selecting one for each topic as the `prepareCoursesData` does.
export const prepareTopicCoursesData = ({
	topicData,
	coursesData,
	intl,
	isOnDemandTable,
	isMember
}: PrepareTopicCoursesDataProps): CoursesTableProps['courses'] => {
	if (!topicData || !coursesData) {
		return [];
	}
	const { locale, messages, formatMessage } = intl;
	return coursesData.data
		.map((course) => {
			const productCode = course.product?.code;
			const totalCoursesForProduct = coursesData?.aggregations
				?.find((aggregation) => aggregation?.name === 'productEvents')
				?.buckets?.find((bucket) => bucket?.key === productCode)?.value;
			if (!productCode || !totalCoursesForProduct) {
				return undefined;
			}

			const productBrand = formatters.formatEnum(course.product?.brand, {
				options: ContentTransformers.brand,
				messages
			});

			const productName =
				formatters.formatTranslation(course.product?.name, {
					locale
				}) ?? productCode;
			return transformContentToCourseTableItem({
				content: course,
				topicData,
				locale,
				messages,
				isOnDemandTable,
				isMember,
				formatMessage,
				courseTitle: {
					title: productName,
					brand: productBrand
				},
				totalCoursesForProduct
			});
		})
		.filter(booleanFilter);
};
/**
 * Extracts and transforms the necessary data for the Courses Table.
 * One of the requirements for this table is to display a fixed number of topics,
 * however certain topics can have multiple products, e.g. AM topic has 3 products: _AIM_, _AM_, _IM_;
 * in such case we need to display 4 topics plus 3 product entries from _AM_ Topic.
 */
export const prepareCoursesData = ({
	topicData,
	coursesData,
	intl,
	isOnDemandTable,
	toFilterTopicCode,
	isMember
}: PrepareCoursesDataProps): CoursesTableProps['courses'] => {
	if (!topicData || !coursesData) {
		return [];
	}
	const { locale, messages, formatMessage } = intl;
	const courses: CoursesTableProps['courses'] = [];

	const coursesGroupedByProduct = groupBy(coursesData.data, (course) => course.product?.code);
	for (const [productCode, productCourses] of Object.entries(coursesGroupedByProduct)) {
		if (productCode === 'undefined') {
			continue;
		}

		const totalCoursesForProduct = coursesData?.aggregations
			?.find((aggregation) => aggregation?.name === 'productEvents')
			?.buckets?.find((bucket) => bucket?.key === productCode)?.value;

		const filteredCourses = !toFilterTopicCode
			? productCourses
			: productCourses.filter(
					(c) => !c.product?.topics.some((t) => t.code === toFilterTopicCode)
			  );
		const topCourse = sortBy(filteredCourses, (course) =>
			course.startDateTime ? new Date(course.startDateTime) : new Date()
		)[0];
		if (!totalCoursesForProduct || !topCourse) {
			continue;
		}

		const productName =
			formatters.formatTranslation(topCourse.product?.name, {
				locale
			}) ?? productCode;
		const productBrand = formatters.formatEnum(topCourse.product?.brand, {
			options: ContentTransformers.brand,
			messages
		});

		const course = transformContentToCourseTableItem({
			content: topCourse,
			topicData,
			locale,
			messages,
			isOnDemandTable,
			isMember,
			formatMessage,
			courseTitle: {
				title: productName,
				brand: productBrand
			},
			totalCoursesForProduct
		});

		courses.push(course);
	}

	return sortCoursesByTopic({ courses, topicData });
};
